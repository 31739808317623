import * as crypto from "crypto-js"
import React from "react"
import moment from "moment"
import { store } from "src/redux/store"
import { UserService } from "src/service/user.service"
import { HereService } from "src/service/here.service"
import { SettingsService } from "src/service/settings.service"
import { config } from "src/config/config"
import i18next from "i18next"
import { Translates } from "src/i18n/i18n"
import { PrepareMsalConfigParams } from "./types/types"
import {
  BULK_UPLOADING,
  SCHEDULE_STATUS_CONSTANTS,
  SECONDARY_PHONE,
  SHARE_EVENTS,
  STATUS_CONSTANTS,
  USER_STATUS_CONSTANTS,
  US_STATE_MAP,
  currencyFormatRegex,
  disallowedExtensions,
  languages
} from "src/utils/constants"
import Papa from "papaparse"
import {
  setCSVUserColumnList,
  setUserFileData,
  setUserMappingData
} from "src/redux/slices/usersSlice"
import {
  setCSVScheduleColumnList,
  setScheduleFileData
} from "src/redux/slices/scheduleSlice"
import {
  setCSVLocationColumnList,
  setLocationFileData
} from "src/redux/slices/settingsSlice"
import {
  setCSVReservationColumnList,
  setReservationFileData
} from "src/redux/slices/reservationsSlice"
import {
  setCSVUploadFileColumnListAreaRange,
  setCSVUploadFileColumnListDeparment,
  setCSVUploadFileColumnListVariance,
  setUploadFileData
} from "src/redux/slices/uploadFileSlice"
import { setConfigDetails } from "src/redux/slices/configSlice"
export const getUniqueKey = () => {
  return (Math.random() + 1).toString(36).substring(7)
}
//istanbul ignore next
export const getKeys = (valKey: string) => {
  let keys = store.getState()?.auth?.keys
  let result = keys.find(key => {
    return key?.secretKey?.includes(valKey)
  })
  if (result) {
    return result.secretValue
  }
  return ""
}
//istanbul ignore next
export const encrypt = plaintext => {
  let SecretKey: string = getKeys("UrlEncryptionSecretKey")
  let IV: string = getKeys("UrlEncryptionIVKey")
  if (SecretKey && IV) {
    let keyWA = crypto.enc.Utf8.parse(SecretKey)
    let ivWA = crypto.enc.Utf8.parse(IV)
    let encrypted = crypto.AES.encrypt(plaintext, keyWA, { iv: ivWA })
    return encrypted.toString()
  }
  return ""
}
export const encryptBulkColumns = columns => {
  let result = columns.map(column => {
    return {
      dataAttribute: encrypt(column.dataAttribute),
      csvAttribute: encrypt(column.csvAttribute)
    }
  })
  return result
}
//istanbul ignore next
export const decrypt = chiper => {
  let SecretKey: string = getKeys("UrlEncryptionSecretKey")
  let IV: string = getKeys("UrlEncryptionIVKey")
  if (SecretKey && IV) {
    let keyWA = crypto.enc.Utf8.parse(SecretKey)
    let ivWA = crypto.enc.Utf8.parse(IV)
    let decrypted = crypto.AES.decrypt(chiper, keyWA, { iv: ivWA })
    decrypted = decrypted.toString(crypto.enc.Utf8)

    return decrypted
  }
  return ""
}
export const useQuery = () => {
  const { search } = window.location
  return React.useMemo(() => new URLSearchParams(search), [search])
}
export const getQueryParam = key => {
  let search = window.location.search
  let params = new URLSearchParams(search)
  return params.get(key)
}
export const days = (date_1, date_2) => {
  let difference = date_1.getTime() - date_2.getTime()
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))
  return TotalDays
}

//istanbul ignore next
export const filterByHelper = (filters: any, data, others: any = {}) => {
  let filtered = [...(data || [])]

  filters.forEach((f: any) => {
    if (f.func === "equalString" || f.func === "equals") {
      filtered = filtered.filter((d: any) => {
        // --------------- multi select ---------------
        if (Array.isArray(f.value)) {
          if (d[f.field]) {
            return f.value.includes(
              d[f.field].replace(/\s*\([^)]*\)/g, "").trim()
            )
          }
          return false
        }
        // ---------------- single string ----------------
        else if (d[f.field] && f.value) {
          return (
            d[f.field]
              .replace(/\s*\([^)]*\)/g, "")
              .trim()
              .toLowerCase() ===
            f.value
              .replace(/\s*\([^)]*\)/g, "")
              .trim()
              .toLowerCase()
          )
        }
        return false
      })
    }
    if (f.func === "configEqualString") {
      filtered = filtered.filter((d: any) => {
        // --------------- multi select ---------------
        if (Array.isArray(f.value)) {
          if (d.configName && d.configVersion) {
            const combinedField = `${d.configName} - ${d.configVersion}`
            return f.value.some(
              (val: string) =>
                val
                  .replace(/\s*\([^)]*\)/g, "")
                  .trim()
                  .toLowerCase() === combinedField.toLowerCase()
            )
          }
          return false
        }
        // ---------------- single string ----------------
        else if (d.configName && d.configVersion && f.value) {
          const combinedField = `${d.configName} - ${d.configVersion}`
          return (
            combinedField.toLowerCase() ===
            f.value
              .replace(/\s*\([^)]*\)/g, "")
              .trim()
              .toLowerCase()
          )
        }
        return false
      })
    }
    if (f.func === "includes") {
      filtered = filtered.filter((d: any) => {
        if (d[f.field] && f.value) {
          if (
            (f.value && d[f.field]?.includes(f.value)) ||
            d[SECONDARY_PHONE]?.includes(f.value) ||
            d[f.field]?.toString() === f.value?.toString() ||
            d[f.field]?.toString() === SECONDARY_PHONE
          ) {
            return true
          }
        }
        return false
      })
    }

    if (f.func === "toHave") {
      filtered = filtered.filter((d: any) => {
        if (d[f.field] && f.value) {
          if (f.value && d[f.field].toLowerCase() === "active") {
            return true
          }
        }
        return false
      })
    }
    if (f.func === "toNotHave") {
      filtered = filtered.filter((d: any) => {
        if (d[f.field] && f.value) {
          if (
            f.value &&
            ![
              USER_STATUS_CONSTANTS.In_ACtive,
              USER_STATUS_CONSTANTS.InACtive
            ].includes(d[f.field].toLowerCase())
          ) {
            return true
          }
        }
        return false
      })
    }
    if (f.func === "showAll") {
      const STATUSES = [
        USER_STATUS_CONSTANTS.Active,
        USER_STATUS_CONSTANTS.InACtive,
        USER_STATUS_CONSTANTS.Invited,
        USER_STATUS_CONSTANTS.In_ACtive
      ]
      filtered = filtered.filter((d: any) => {
        if (d[f.field] && f.value) {
          if (STATUSES.includes(d[f.field].toLowerCase())) {
            return true
          }
        }
        return false
      })
    }
    if (f.func === "phone") {
      filtered = filtered.filter((d: any) => {
        if (d[f.field] && f.value) {
          return (
            d[f.field]
              ?.replace("(", "")
              .replace(")", "")
              .replace("+", "")
              .replace(/\s/g, "")
              .replace("-", "") ===
            f.value
              ?.replace("(", "")
              .replace(")", "")
              .replace("+", "")
              .replace(/\s/g, "")
              .replace("-", "")
          )
        }
        return false
      })
    }
    if (f.func === "greater") {
      filtered = filtered.filter((d: any) => {
        return new Date(d[f.field]).toISOString().split("T")[0] > f.value
      })
    }
    if (f.func === "equalDate") {
      filtered = filtered.filter((d: any) => {
        let value = d[f.field]
        if (f.isConverstionRequired) {
          let date = moment.utc(value).local().format("DD-MM-YYYY")
          value = `${date}`
        } else {
          value = moment(d[f.field]).format("DD-MM-YYYY")
        }
        return value === moment(f.value).format("DD-MM-YYYY")
      })
    }
    if (f.func === "lesser") {
      filtered = filtered.filter((d: any) => {
        return new Date(d[f.field]).toISOString().split("T")[0] < f.value
      })
    }
    if (f.func === "lesserAndEqual") {
      filtered = filtered.filter((d: any) => {
        return d[f.field].split("T")[0] <= f.value
      })
    }
    if (f.func === "greaterAndEqual") {
      filtered = filtered.filter((d: any) => {
        return d[f.field].split("T")[0] >= f.value
      })
    }
    if (f.func === "lesserAndEqualDate") {
      filtered = filtered.filter((d: any) => {
        return new Date(d[f.field]).toISOString().split("T")[0] <= f.value
      })
    }
    if (f.func === "greaterAndEqualDate") {
      filtered = filtered.filter((d: any) => {
        return new Date(d[f.field]).toISOString().split("T")[0] >= f.value
      })
    }
    if (f.func === "custom") {
      let { path } = others
      filtered = filtered.filter((d: any) => {
        if (d[path[0]] && d[path[0]][path[1]]) {
          let regions = d[path[0]][path[1]]
          let rData = regions.filter(rg => {
            return (
              rg.regionNumber === f.field && f.value.includes(rg.regionValue)
            )
          })
          return rData.length ? true : false
        }
        return false
      })
    }
  })
  return filtered
}
export const validateEmail = email => {
  if (!email) return false
  let regex =
    /^(([^<>()[\]\.,:\s@\"]+(\.[^<>()[\]\.,:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,:\s@\"]+\.)+[^<>()[\]\.,:\s@\"]{2,})$/i
  let check = email.match(regex)
  return check ? true : false
}
export const formatPhoneNumber = phoneNumberString => {
  const cleaned = phoneNumberString.replace(/^\+\d+|[\s()+-]/g, "")
  let match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    let intlCode = "+1"
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return null
}
export const validatePhoneNumber = phoneNumber => {
  if (!phoneNumber) return false
  let regex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  return regex.test(phoneNumber)
}

export const validateInteger = fieldValue => {
  if (!fieldValue) return false
  const regex = /^[0-9\b]+$/
  return regex.test(fieldValue)
}

export const validateDecimal = fieldValue => {
  const regex = /^(\d+)?(\.\d+)?$/
  if (!fieldValue) {
    return false
  } else {
    return regex.test(fieldValue)
  }
}
export const validateOnlyInteger = fieldValue => {
  const regex = /^\d+$/
  if (!fieldValue) {
    return false
  } else {
    return regex.test(fieldValue)
  }
}
export const validateVariable = fieldValue => {
  if (!fieldValue) return false
  const regex = /^[a-zA-Z][a-zA-Z0-9]*$/
  return regex.test(fieldValue)
}
export const encryptUrlParams = myString => {
  const encodedWord = crypto.enc.Utf8.parse(myString) // encodedWord Array object
  const encoded = crypto.enc.Base64.stringify(encodedWord) // string: 'NzUzMjI1NDE='
  return encoded
}

//istanbul ignore next
export const decryptUrlParams = encoded => {
  try {
    const encodedWord = crypto.enc.Base64.parse(encoded) // encodedWord via Base64.parse()
    const decoded = crypto.enc.Utf8.stringify(encodedWord) // decode encodedWord via Utf8.stringify() '75322541'
    return decoded
  } catch (err) {
    return ""
  }
}

const _logOut = () => {
  localStorage.clear()
  window.location.href = "/login"
}

export const parseToken = token => {
  if (!token) return ""
  try {
    const _base64Payload = token.split(".")[1]
    const _payloadBuffer = Buffer.from(_base64Payload || "", "base64")
    const parsedObj = JSON.parse(_payloadBuffer.toString())
    return parsedObj
  } catch (_err) {
    _logOut()
    return ""
  }
}

export const getCurrentUserRole = (type = "Permissions") => {
  const _accessToken = localStorage.getItem("accessToken")
  let parsedObj = parseToken(_accessToken)
  if (type === "USER_ID") {
    return parsedObj["UserId"]
  } else if (type === "ROLES_PERMISSIONS") {
    return parsedObj["RolesAndPermissions"]
  } else if (type === "USER_REGIONS") {
    return parsedObj["UserRegions"]
  }
  return parsedObj[
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
  ]
}

export const filterRegionalUserData = (regionalUserData, regionMappingData) => {
  const region1Data = regionMappingData
    .filter(item => {
      return item.RegionNumber === 1
    })
    .map(({ RegionValue }) => RegionValue)

  const region2Data = regionMappingData
    .filter(item => {
      return item.RegionNumber === 2
    })
    .map(({ RegionValue }) => RegionValue)

  const region3Data = regionMappingData
    .filter(item => {
      return item.RegionNumber === 3
    })
    .map(({ RegionValue }) => RegionValue)

  const region4Data = regionMappingData
    .filter(item => {
      return item.RegionNumber === 4
    })
    .map(({ RegionValue }) => RegionValue)

  let filteredData: any
  let filteredRegion1Data = []
  let filteredRegion2Data = []
  let filteredRegion3Data = []
  let filteredRegion4Data = []
  // filter by region 1
  if (region1Data.length > 0) {
    filteredRegion1Data = regionalUserData.filter(function (itm) {
      return region1Data.indexOf(itm.region1) > -1
    })
  }
  // filter by region 2
  if (region2Data.length > 0) {
    filteredRegion2Data = regionalUserData.filter(function (itm) {
      return region2Data.indexOf(itm.region2) > -1
    })
  }
  // filter by region 3
  if (region3Data.length > 0) {
    filteredRegion3Data = regionalUserData.filter(function (itm) {
      return region3Data.indexOf(itm.region3) > -1
    })
  }
  // filter by region 4
  if (region4Data.length > 0) {
    filteredRegion4Data = regionalUserData.filter(function (itm) {
      return region4Data.indexOf(itm.region4) > -1
    })
  }
  filteredData = [
    ...filteredRegion1Data,
    ...filteredRegion2Data,
    ...filteredRegion3Data,
    ...filteredRegion4Data
  ]

  // remove duplicates added by filter conditions
  filteredData = filteredData.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        t =>
          t.siteId === value.siteId &&
          t.name === value.name &&
          t.banner === value.banner &&
          t.phoneNumber === value.phoneNumber &&
          t.email === value.email &&
          t.region1 === value.region1 &&
          t.address1 === value.address1
      )
  )

  if (
    region1Data.length > 0 ||
    region2Data.length > 0 ||
    region3Data.length > 0 ||
    region4Data.length > 0
  ) {
    return filteredData
  } else {
    return regionalUserData
  }
}

export const getPermissionsForUser = () => {
  const _accessToken = localStorage.getItem("accessToken")
  if (_accessToken) {
    const _base64Payload = _accessToken.split(".")[1]
    const _payloadBuffer = Buffer.from(_base64Payload || "", "base64")
    const _dt = JSON.parse(_payloadBuffer.toString())
    return _dt?.Permissions ? JSON.parse(_dt?.Permissions) : []
  } else {
    return []
  }
}
export const sortTableData = (
  unSortedDataOriginal,
  sortType,
  SortColumn,
  ColumnType
) => {
  let sortedData
  let unSortedData = JSON.parse(JSON.stringify(unSortedDataOriginal))
  switch (ColumnType) {
    case "Date":
      if (sortType === "DESC") {
        sortedData = unSortedData.sort((a, b) => {
          return (
            new Date(b[SortColumn]).getTime() -
            new Date(a[SortColumn]).getTime()
          )
        })
      } else if (sortType === "ASC") {
        sortedData = unSortedData.sort((a, b) => {
          return (
            new Date(a[SortColumn]).getTime() -
            new Date(b[SortColumn]).getTime()
          )
        })
      }
      break
    case "Interger":
      if (sortType === "DESC") {
        sortedData = unSortedData.sort((a, b) => {
          if (a[SortColumn] > b[SortColumn]) {
            return -1
          }
        })
      } else if (sortType === "ASC") {
        sortedData = unSortedData.sort((a, b) => {
          if (a[SortColumn] < b[SortColumn]) {
            return -1
          }
        })
      }

      break
  }
  return sortedData
}

export const replaceAll = (sourceStr, searchStr, replace) => {
  if (sourceStr) {
    return sourceStr.split(searchStr).join(replace)
  }
  return ""
}

export const replaceAllMultiple = (sourceStr, searchArr, replaceArr) => {
  let locSourceStr = sourceStr
  if (locSourceStr) {
    if (
      Array.isArray(searchArr) &&
      Array.isArray(replaceArr) &&
      searchArr.length === replaceArr.length
    ) {
      searchArr.forEach((item, inx) => {
        locSourceStr = locSourceStr.replace(item, replaceArr[inx])
      })
    }
  }
  return locSourceStr
}
export const handleFocus = event => event.target.select()

export const containsAll = (child, parent) => {
  for (let i of child) {
    if (!parent.includes(i)) return false
  }
  return true
}
export const numericSort = (a, b) => {
  const numA = parseInt(a, 10)
  const numB = parseInt(b, 10)
  return numA - numB
}

export const checkPhoneValidations = phone => {
  const regexNumbers = /^[0-9\b]+$/
  const regexNumberschar = /^(?=.*?[1-9])[0-9\s()+-]+$/

  if (phone?.length < 16) {
    return true
  } else if (phone?.length && regexNumbers.test(phone)) {
    return true
  } else if (phone?.length && !regexNumberschar.test(phone)) {
    return true
  } else if (
    phone?.length > 14 &&
    (phone[0] !== "+" ||
      phone[1] !== "1" ||
      phone[2] !== "(" ||
      phone[3] === " " ||
      phone[4] === " " ||
      phone[5] === " " ||
      phone[6] !== ")" ||
      phone[7] !== " " ||
      phone[8] === " " ||
      phone[9] === " " ||
      phone[10] === " " ||
      phone[11] !== "-" ||
      phone[12] === " " ||
      phone[13] === " " ||
      phone[14] === " " ||
      phone[15] === " ")
  ) {
    return true
  } else {
    return false
  }
}

export const getLocationsByAccountHelper = async customerId => {
  const userService = new UserService()
  return userService.getAllRegionalsByAccount(customerId)
}
export const mapRegionValues = locationAccountData => {
  let r1: any = []
  let r2: any = []
  let r3: any = []
  let r4: any = []
  locationAccountData.map((m: any) => {
    if (m.region1) r1.push(m.region1)
    if (m.region2) r2.push(m.region2)
    if (m.region3) r3.push(m.region3)
    if (m.region4) r4.push(m.region4)
  })
  r1 = formatRGValues(r1)
  r2 = formatRGValues(r2)
  r3 = formatRGValues(r3)
  r4 = formatRGValues(r4)
  return { r1, r2, r3, r4 }
}

export const formatRGValues = regionValues => {
  let regionValuesArray: any = []
  for (let value of regionValues) {
    if (value) {
      let splitString = value.split(",")
      for (let item of splitString) {
        if (item) regionValuesArray.push(item)
      }
    }
  }
  return Array.from(new Set(regionValuesArray))
}
export const _userRole: string = getCurrentUserRole()
export const handleSuperVisorPortalRedirect = (record, component) => {
  let _userRole = getCurrentUserRole()

  let name = localStorage.getItem("name")
  let userFirstName = name?.split(" ")[0] ? name?.split(" ")[0] : ""
  let userLastName = name?.split(" ")[1]
    ? name.substring(userFirstName.length + 1)
    : "NOLNAME"
  let fromCustomer = "Y"
  let supervisorPortalURL = config.supervisorURL
  let eventId: any
  if (component === "schedule") {
    eventId = record.idEvent
  } else {
    eventId = record.eventId
  }
  let queryString = `?FromCustomer=${fromCustomer}&UserFirstName=${encryptUrlParams(
    userFirstName
  )}&UserLastName=${encryptUrlParams(userLastName)}&userType=${encryptUrlParams(
    _userRole
  )}&eventIdentifier=${encryptUrlParams(record.eventIdentifier)}&_proof=${encryptUrlParams(record.password)}`
  let redirectURL = supervisorPortalURL + queryString
  const hostName = window.location.hostname
  let msalToken: any = localStorage.getItem("msalAccessToken")
  document.cookie = `${userFirstName}${userLastName}-ADtoken=${msalToken}; domain=${hostName}; path=/; secure`;
  window.open(redirectURL, "_blank")
}

export const validateWisTagsValue = value => {
  if (!value) {
    return ""
  } else {
    const numericValue = parseInt(value, 10)
    if (numericValue > 20000) {
      return "Value must not exceed 20000."
    } else if (numericValue < 0) {
      return "Invalid Value"
    } else if (numericValue % 100 !== 0) {
      return "Value must be a multiple of 100."
    } else {
      return ""
    }
  }
}
export const SORT_TYPES = {
  ASC: "ASC",
  DESC: "DESC"
}
export const sortByKey = (data, sortBy, sortType) => {
  if (!data || !data.length) return []
  let sorted = []
  if (sortType === SORT_TYPES.ASC)
    sorted = data.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1))
  if (sortType === SORT_TYPES.DESC)
    sorted = data.sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : -1))
  return sorted
}
export function translate(key) {
  if (i18next.exists(key)) {
    return i18next.t(key)
  } else {
    return ""
  }
}
export const searchLiveEvents = (input, data) => {
  const searchTerm = input.trim().toLowerCase()
  return data?.filter(item => {
    // Modify the conditions based on your search requirements
    const nameMatch = item?.storeName?.toLowerCase().includes(searchTerm)
    const siteIdMatch = item?.siteId?.toLowerCase().includes(searchTerm)
    const addressMatch = item?.address
      ?.toString()
      .toLowerCase()
      .includes(searchTerm)
    return nameMatch || siteIdMatch || addressMatch
  })
}
export const filterAndSort = array => {
  const filteredArray = array?.filter(el => el !== null && el !== "")
  const uniqueValues = Array.from(new Set(filteredArray))
  const sortedArray = [...uniqueValues].sort((a: any, b: any) =>
    a.localeCompare(b)
  )
  return sortedArray
}
export const sortData = array => {
  if (!Array.isArray(array)) {
    return []
  }
  const sortedArray = array.slice().sort((a, b) => {
    const valueA = a.toLowerCase()
    const valueB = b.toLowerCase()
    return valueA.localeCompare(valueB)
  })

  return sortedArray
}

export const getRegionData = (
  userData,
  setFilteredRegion1Values,
  setFilteredRegion2Values,
  setFilteredRegion3Values,
  setFilteredRegion4Values
) => {
  const filterAndSetValues = regionName => {
    const newArray = userData?.map(a => a[regionName])
    const filteredArray = newArray?.filter(el => el !== null && el !== "")
    const uniqueValues = Array.from(new Set(filteredArray))
    switch (regionName) {
      case "region1":
        setFilteredRegion1Values(uniqueValues)
        break
      case "region2":
        setFilteredRegion2Values(uniqueValues)
        break
      case "region3":
        setFilteredRegion3Values(uniqueValues)
        break
      case "region4":
        setFilteredRegion4Values(uniqueValues)
        break
      default:
        break
    }
  }

  filterAndSetValues("region1")
  filterAndSetValues("region2")
  filterAndSetValues("region3")
  filterAndSetValues("region4")
}
export const handleResetAll = ({
  setRegion1,
  setRegion2,
  setRegion3,
  setRegion4,
  setCountryName,
  setStateName,
  setDate,
  setStatusName,
  isSchedulePage
}) => {
  setRegion1([])
  setRegion2([])
  setRegion3([])
  setRegion4([])
  setStateName([])
  setCountryName([])
  setStatusName(
    isSchedulePage
      ? [
          SCHEDULE_STATUS_CONSTANTS.INPROGRESS,
          SCHEDULE_STATUS_CONSTANTS.NOTSTARTED,
          SCHEDULE_STATUS_CONSTANTS.Completed,
          SCHEDULE_STATUS_CONSTANTS.Locked
        ]
      : []
  )
  setDate({
    scheduledDateTime: ""
  })
}
export const handleScheduleResetAll = ({
  setRegion1,
  setRegion2,
  setRegion3,
  setRegion4,
  setCountryName,
  setStateName,
  setStartDate,
  setEndDate,
  setStatusName,
  isSchedulePage,
  setConfigName
}) => {
  setRegion1([])
  setRegion2([])
  setRegion3([])
  setRegion4([])
  setStateName([])
  setCountryName([])
  setStatusName(
    isSchedulePage
      ? [
          SCHEDULE_STATUS_CONSTANTS.INPROGRESS,
          SCHEDULE_STATUS_CONSTANTS.NOTSTARTED,
          SCHEDULE_STATUS_CONSTANTS.Completed,
          SCHEDULE_STATUS_CONSTANTS.Locked,
          SCHEDULE_STATUS_CONSTANTS.LOCK_IN_PROGRESS,
          SCHEDULE_STATUS_CONSTANTS.SOFT_CLOSE
        ]
      : []
  )
  setStartDate(moment().subtract(1, "days").format("YYYY-MM-DD"))
  setEndDate(moment().add(30, "days").format("YYYY-MM-DD"))
  setConfigName([])
}
export const dateChangeHandler = (e, setDate, date) => {
  const val = e.target.value || ""
  if (e.target.name === "scheduledDateTime") {
    setDate({
      scheduledDateTime: val
    })
  } else if (e.target.name) {
    setDate({
      ...date,
      [e.target.name]: val
    })
  }
}
export const handleChangeRegion = (
  event,
  id,
  setRegion1,
  setRegion2,
  setRegion3,
  setRegion4
) => {
  const {
    target: { value }
  } = event
  if (id == 1) {
    setRegion1(typeof value === "string" ? value.split(",") : value)
  } else if (id == 2) {
    setRegion2(typeof value === "string" ? value.split(",") : value)
  } else if (id == 3) {
    setRegion3(typeof value === "string" ? value.split(",") : value)
  } else if (id == 4) {
    setRegion4(typeof value === "string" ? value.split(",") : value)
  }
}
export const handleChangeCountryVal = (event, setCountryName) => {
  const {
    target: { value }
  } = event
  setCountryName(typeof value === "string" ? value.split(",") : value)
}
export const handleChangeSiteIdVal = (event, setSiteIdSelects) => {
  const {
    target: { value }
  } = event
  setSiteIdSelects(typeof value === "string" ? value.split(",") : value)
}
export const handleChangeStatusVal = (
  event,
  setStatusName,
  statusName,
  uniqStatus
) => {
  const value = event.target.value
  if (value[value.length - 1] === "All") {
    setStatusName(
      statusName.length === uniqStatus.length
        ? []
        : uniqStatus.map(i => i.value)
    )
    return
  }
  setStatusName(value)
}

export const handleChangeStateVal = (event, setStateName) => {
  const {
    target: { value }
  } = event
  setStateName(typeof value === "string" ? value.split(",") : value)
}
export const handleChangeConfigVal = (event, setConfigName) => {
  const {
    target: { value }
  } = event
  setConfigName(typeof value === "string" ? value.split(",") : value)
}
export const mapEventsData = eventsData => {
  let countryValues: any = []
  let stateValues: any = []
  let statusValues: any = []
  let eventStartDateValues: any = []
  eventsData.map((m: any) => {
    countryValues.push(m.country)
    stateValues.push(m.state)
    statusValues.push(m.status)
    eventStartDateValues.push(m.eventStartDate)
  })
  return { countryValues, stateValues, statusValues, eventStartDateValues }
}
export const isValidInputValue = e => {
  const inputValue = e.target.value
  const maxLength = 6
  return /^-?\d*$/.test(inputValue) && inputValue.length <= maxLength
}
export const getTranslations = (t, key) => {
  return t(Translates[key] ?? key)
}
const WIS_DOMAIN: string = "wisintl.com"
export const prepareMsalConfig = (
  params: PrepareMsalConfigParams,
  msalConfig,
  isLoggedIn
) => {
  if (isLoggedIn) {
    let username = localStorage.getItem("username")
    if (username) {
      let splitStr = username.split("@")
      let domain = splitStr[1]
      params = { domain_hint: domain, login_hint: username }
    }
  }
  let mConfig: any = { ...msalConfig }
  let domain = params.domain_hint
  let auth: any = process.env.REACT_APP_AD_AUTHORITY_URL || ""
  if (domain !== WIS_DOMAIN) {
    auth = auth + process.env.REACT_APP_AD_EXT_AUTH
  } else {
    auth = auth + process.env.REACT_APP_AD_INT_AUTH
  }
  mConfig.auth.authority = auth
  return mConfig
}

export const populateCSVFileWithDoNotChangeTheColumnHeader = () =>
  Translates.Populate_the_CSV_file_with_the_import_data_Do_not_change_the_column_headers
export const Download_template_right_to_create_a_CSV_file = () => {
  return Translates.Download_the_template_from_the_right_to_create_a_CSV_file
}
export const Upload_the_CSV_file_in_the_upload_box = () => {
  return Translates.Upload_the_CSV_file_in_the_upload_box_provided_below
}
export const Csv_Template = () => {
  return Translates.CSV_Templates
}
export const custom_title_Info = () => {
  return Translates.Info
}

//istanbul ignore next
export const processCSV = (str: any, dispatch, typeOfUploading: any) => {
  const parsedData = Papa.parse(str, {
    header: true,
    skipEmptyLines: true
  })
  const parsedArray = parsedData.data
  const _temp = parsedData.meta.fields
  let newArray = parsedArray.map(row => {
    const newRow = {}
    _temp.forEach(key => {
      let val = row[key] || ""
      if (typeof val === "string") {
        val = val.replace(/["\\]/g, "")
        val = val.replace(/,/g, "")

        // Handle values wrapped in double quotes
        if (val.charAt(0) === '"' && val.charAt(val.length - 1) === '"') {
          val = val.slice(1, -1) // Remove the double quotes
          val = val.replace(/""/g, '"') // Replace double double quotes with a single quote
        }
        // Remove escaping backslashes
        val = val.replace(/\\"/g, '"')
        if (
          typeOfUploading === BULK_UPLOADING.LOCATION_FILE_UPLOADING &&
          key.toLowerCase() === "state"
        ) {
          val = US_STATE_MAP[val.toLowerCase()] || val
        }
        newRow[key] = val
      }
    })
    return newRow
  })
  if (newArray.length) {
    if (typeOfUploading === BULK_UPLOADING.USER_DEATILS_FILE_UPLOADING) {
      dispatch(setCSVUserColumnList(_temp))
      dispatch(setUserMappingData({}))
      dispatch(setUserFileData(JSON.stringify(newArray)))
    } else if (typeOfUploading === BULK_UPLOADING.SCHEDULE_FILE_UPLOADING) {
      dispatch(setCSVScheduleColumnList(_temp))
      dispatch(setScheduleFileData(JSON.stringify(newArray)))
    } else if (typeOfUploading === BULK_UPLOADING.LOCATION_FILE_UPLOADING) {
      dispatch(setCSVLocationColumnList(_temp))
      dispatch(setLocationFileData(JSON.stringify(newArray)))
    } else if (typeOfUploading === BULK_UPLOADING.DEVICE_RESERVATION) {
      dispatch(setCSVReservationColumnList(_temp))
      dispatch(setReservationFileData(JSON.stringify(newArray)))
    } else if (typeOfUploading === BULK_UPLOADING.UPLOADFILE) {
      dispatch(setCSVUploadFileColumnListDeparment(_temp))
      dispatch(setCSVUploadFileColumnListVariance(_temp))
      dispatch(setCSVUploadFileColumnListAreaRange(_temp))
      dispatch(setUploadFileData(JSON.stringify(newArray)))
    }
  } else if (newArray.length < 1) {
    if (typeOfUploading === BULK_UPLOADING.USER_DEATILS_FILE_UPLOADING) {
      dispatch(setUserFileData([]))
    } else if (typeOfUploading === BULK_UPLOADING.SCHEDULE_FILE_UPLOADING) {
      dispatch(setScheduleFileData([]))
    } else if (typeOfUploading === BULK_UPLOADING.LOCATION_FILE_UPLOADING) {
      dispatch(setLocationFileData([]))
    } else if (typeOfUploading === BULK_UPLOADING.DEVICE_RESERVATION) {
      dispatch(setReservationFileData([]))
    } else if (typeOfUploading === BULK_UPLOADING.UPLOADFILE) {
      dispatch(setUploadFileData([]))
    }
  }
}

//istanbul ignore next
export const createImageFile = (svgBlob: Blob): Promise<File> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      if (ctx) {
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        canvas.toBlob(blob => {
          if (blob) {
            const fileQRCode = new File([blob], SHARE_EVENTS.File_Name, {
              type: "image/png"
            })
            resolve(fileQRCode)
          } else {
            reject(new Error("Error creating image file."))
          }
        }, "image/png")
      } else {
        reject(new Error("Error creating canvas context."))
      }
    }
    img.src = URL.createObjectURL(svgBlob)
  })
}

const MILLISECONDS_PER_SECOND = 1000
const SECONDS_PER_HOUR = 3600
const HOURS_PER_DAY = 24
export const millisecondsToDays = milliseconds =>
  milliseconds / (MILLISECONDS_PER_SECOND * SECONDS_PER_HOUR * HOURS_PER_DAY)

export const fileExtensionCheck = ({ fileName, setAlertError, t }) => {
  if (!isFileNameAllowed({ fileName, setAlertError, t })) {
    return false
  }
  return true
}

export const getLastFileExtension = filename => filename.split(".").pop()

const getAllFileExtension = filename => {
  if (filename.split(".").length > 1) {
    const allExtension = filename.match(/\.[^.]+/g)
    let newAllExtension: any = []
    allExtension.forEach(str => {
      if (!/^[a-zA-Z]+$/.test(str.substring(1))) {
        newAllExtension = []
      } else {
        newAllExtension.push(str.toLowerCase())
      }
    })
    return newAllExtension
  }

  return filename
}

const isFileNameAllowed = ({ fileName, setAlertError, t }) => {
  const allExtension = getAllFileExtension(fileName)
  for (const extension of disallowedExtensions) {
    if (allExtension.includes(extension.toLowerCase())) {
      setAlertError(oldVal => ({
        ...oldVal,
        severity: "error",
        title: getTranslations(t, Translates.Incorrect_File_Format),
        content: `${getTranslations(
          t,
          Translates.File_name_or_extension_can_not_be_contain_the_following_letters
        )} ${disallowedExtensions.join(",")}`
      }))
      return false
    }
  }
  return true
}

const specialCharsRegex = /[@$*%\/\\]/

export const validateInputString = (inputStr: string) => {
  if (!inputStr) return false
  if (specialCharsRegex.test(inputStr)) {
    return false
  } else {
    return true
  }
}

export const createURLFromByteArray = encoded => {
  if (encoded) {
    try {
      const byteCharacters = atob(encoded)
      let byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)

      // Convert the byte array to a Blob
      const blob = new Blob([byteArray], { type: "application/octet-stream" })

      // Create a URL for the Blob
      return URL.createObjectURL(blob)
    } catch (err) {
      throw err
    }
  } else {
    console.error("No file info found")
  }
}

export const setWeekFieldError = ({ setWeekIVFields, field, val, t }) => {
  setWeekIVFields(oldVal => ({
    ...oldVal,
    [field]: ""
  }))

  const isValid = validateInputString(val)
  if (val && !isValid) {
    setWeekIVFields(oldVal => ({
      ...oldVal,
      [field]: getTranslations(t, Translates.Invalid_Input)
    }))
  }
}

export const getJSONParse = str => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return str
  }
}

export const configFileDecryption = (configP, isParse = false) => {
  let result = configP ? decrypt(configP) : ""

  if (isParse) {
    result = getJSONParse(result)
  }
  return result
}

export const getResCDsConfigDecryption = data => {
  return data.map(i => ({
    ...i,
    configuration: configFileDecryption(i.configuration)
  }))
}

export const getConfigurationDecryptAndSetRedux = configData => {
  if (configData.isGetConfigDecrypt) {
    return JSON.parse(configData.configDetails.configuration)
  } else if (configData.configDetails.configuration) {
    const data1 = {
      ...configData.configDetails,
      configuration: configFileDecryption(
        configData.configDetails.configuration
      )
    }
    store.dispatch(setConfigDetails(data1))
    return data1.configuration ? JSON.parse(data1.configuration) : ""
  }
  return ""
}

export const downloadJSON = (jsonData, fileName) => {
  const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
    type: "application/json"
  })
  const url = URL.createObjectURL(blob)

  const a = document.createElement("a")
  a.href = url
  a.download = `${fileName}.json`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}
// Getting all API Validation Errors for bulk upload
export const getBulkAPIValidation = errorMsg => {
  // Split the string by ":"
  const parts = errorMsg.split(":")

  let rowPart = ""
  let rowNumber = ""
  let msg = ""

  // Extract the first part as the row part and row number
  if (parts?.length > 1) {
    const firstPart = parts[0].trim() // Row RowNumber
    const lastSpaceIndex = firstPart.lastIndexOf(" ")
    rowPart = firstPart.substring(0, lastSpaceIndex).trim() // Extract "Row"
    rowNumber = firstPart.substring(lastSpaceIndex + 1).trim() // Extract "rowNo"
  }

  // Extract "ErrorMsg" from the second part
  msg = parts[1]?.trim().split(" ").join("_") || ""

  return { rowPart, rowNumber, msg }
}

export const getEventStatusList = ({ t }) => {
  return [
    {
      label: getTranslations(t, Translates.In_Progress),
      value: "In Progress"
    },
    {
      label: getTranslations(t, Translates.Not_Started),
      value: "Not Started"
    },
    { label: getTranslations(t, Translates.Closed), value: "Closed" },
    { label: getTranslations(t, Translates.Locked), value: "Locked" },
    { label: getTranslations(t, Translates.Cancelled), value: "Cancelled" },
    {
      label: getTranslations(t, Translates.Lock_in_progress),
      value: "Lock In Progress"
    },
    {
      label: getTranslations(t, Translates.Soft_Close),
      value: "Soft Close"
    }
  ]
}

export const getConfigStatusList = ({ t }) => {
  return [
    {
      label: getTranslations(t, Translates.Active),
      value: STATUS_CONSTANTS.Active
    },
    {
      label: getTranslations(t, Translates.InActive),
      value: STATUS_CONSTANTS.In_Active
    },
    {
      label: getTranslations(t, Translates.Not_Installed),
      value: STATUS_CONSTANTS.Not_Installed
    }
  ]
}

export const formatWithCurrencyLang = amount => {
  if (
    (amount || amount === 0) &&
    i18next?.language?.toLowerCase() === languages?.FRENCH?.toLowerCase()
  ) {
    return `${amount}`.replace(currencyFormatRegex, ".")
  } else {
    return amount || amount === 0
      ? `${amount}`.replace(currencyFormatRegex, ",")
      : "-"
  }
}

// Ignoring seconds and milliseconds
export const stripSecondsAndMilliseconds = dateStr => {
  let date = new Date(dateStr)
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    0, // Set seconds to 0
    0 // Set milliseconds to 0
  ).getTime()
}

export const sortedValues = (options: any) =>
  options.sort((a, b) => {
    const valueA = a.value
    const valueB = b.value

    // Helper function to determine if a value has leading zeros
    const hasLeadingZeros = value =>
      /^\d+$/.test(value) && value.startsWith("0")

    const hasLeadingZerosA = hasLeadingZeros(valueA)
    const hasLeadingZerosB = hasLeadingZeros(valueB)

    if (hasLeadingZerosA && !hasLeadingZerosB) {
      return -1 // A should come before B
    } else if (!hasLeadingZerosA && hasLeadingZerosB) {
      return 1 // B should come before A
    } else if (hasLeadingZerosA && hasLeadingZerosB) {
      return valueA.localeCompare(valueB) // Both have leading zeros, compare as strings
    }

    // Both are numeric values
    const isNumericA = /^\d+$/.test(valueA)
    const isNumericB = /^\d+$/.test(valueB)

    if (isNumericA && isNumericB) {
      return Number(valueA) - Number(valueB) // Compare numeric values
    } else if (isNumericA) {
      return -1 // Numeric values should come before alphanumeric values
    } else if (isNumericB) {
      return 1 // Alphanumeric values should come after numeric values
    }

    // Compare alphanumeric values as strings
    return valueA.localeCompare(valueB)
  })
  function reject(arg0: string): any {
    throw new Error("Function not implemented.")
  }

